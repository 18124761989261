<template>
    <div class="page">

        <div class="page__header">
            <div class="page__info">
                <div class="page__title">Категории METRO</div>
                <div class="page__desc">Список категорий</div>
            </div>
        </div>

        <div class="page__content">
            <div class="table-responsive">
                <table class="table table-striped">
                    <tr>
                        <th>Название</th>
                        <th></th>
                    </tr>
                    <tr v-for="category in categories"
                        v-bind:key="category.id">

                        <td>{{ category.displayName }}</td>
                        <td>
                            <router-link
                                    v-if="category.categoryParentId === null"
                                    :to="{ name: 'MetroSubcategories', params: {id: category.categoryId } }"
                                    tag="input"
                                    type="button"
                                    class="btn btn-sm btn-warning"
                                    value="Подкатегории"/>

                            <template v-else>
                                <router-link
                                        :to="{ name: 'MetroCategoriesSettings', params: {id: category.categoryId } }"
                                        tag="input"
                                        type="button"
                                        class="btn btn-sm btn-outline-warning"
                                        :value="'Назначить категорию ' + (category.market_category_id !== null ? '(' + category.market_category_id + ')' : '')"/>
                                <router-link
                                        :to="{ name: 'MetroCategoriesProducts', params: {id: category.categoryId } }"
                                        tag="input"
                                        type="button"
                                        class="btn btn-sm btn-outline-info"
                                        value="Товары"/>
                            </template>
                        </td>
                    </tr>
                </table>
            </div>
        </div>

    </div>
</template>


<script>
    import { mapActions } from 'vuex'

    export default {
        name: 'MetroCategories',
        computed: {
            categories() {
                return this.$store.state.metro.metroCategories
            }
        },
        methods: {
            ...mapActions([
                'metroCategories'
            ]),
            async getCategories() {
                await this.metroCategories({
                    id: this.$route.params.id === undefined ? null : this.$route.params.id,
                })
            }
        },
        created() {
            this.getCategories();
        },
        watch: {
            $route() {
                this.getCategories();
            }
        },
    }
</script>

<style lang="scss" scoped>
    .btn {
        margin-right: 10px;
    }
</style>